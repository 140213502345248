<template>
  <div class="input-box">
    <div :class="isFlex?'inputFlex':''">
      <div class="input-title">
        <span class="red" :class="isRedDot?'':'isRedDot'">* </span>
        <span>{{name}}</span>
      </div>

      <div class="input-home">
        <!-- text搜索框类型 -->
        <inputTextModule ref="inputText" :max='max' :min='min' :isDecimal = 'isDecimal' :weekly='weekly' :verifyPhone='verifyPhone'
        :name='name' :disabled='disabled' :isCheckNum = 'isCheckNum' :watchLength = 'watchLength' :promptText='promptText'
          :inputWitdh='inputWitdh' :isCheck='isCheck' :password='password' :textPlaceholder='textPlaceholder'
          :isSumStrLength='isSumStrLength' :maxLengths="maxLengths" :verifyEmail='verifyEmail' v-model="text"></inputTextModule>
      </div>
    </div>
  </div>
</template>

<script>
  import inputTextModule from './module/input_text.vue'

  export default {
    components: {
      inputTextModule
    },
    props: ['name', 'type', 'value',
      'max', 'inputWitdh', 'isFlex',
      'min', 'isCheck', 'isRedDot','weekly',
      'textPlaceholder', 'disabled','watchLength','promptText','verifyPhone',
      'password', 'isSumStrLength', 'maxLengths','isCheckNum','isDecimal','verifyEmail'
    ],
    data() {
      return {
        text: ''
      }
    },
    created() {
      this.text = this.value
    },
    watch: {
      value(val){
        this.text = val
      },
      text(val) {
        this.$emit('input', val)
      },
    },
    methods: {
      verification() {
        return this.$refs.inputText.verification();
      },
      alertMsg() {
        return this.$refs.inputText.alertMsg();
      }
    }
  }
</script>

<style scoped lang="scss">
  .input-box {
    .input-title {
      font-size: 18px;
      margin-bottom: 20px;
      color: #778CA2;
      padding-top: 3px;
      .red {
        color: #E30C0C;
      }
    }

    .inputFlex {
      display: flex;
      line-height: 40px;
    }

    .isRedDot {
      display: none;
    }
  }
</style>
