<template>
  <div class="footer-container">
    <div class="row_1 flex">
      <!-- <img src="@/assets/forestage/images/logo.png" class="logo"> -->
      <img src="@/assets/forestage/images/zhwLogo.png" class="logo">
      <ul class="flex-vc">
        <li class="nav-title mouse-poi flex-vc" @click="navClick(1)">{{$t('nav.navText_1')}}</li>
        <li class="nav-title mouse-poi flex-vc" @click="navClick(2)">{{$t('nav.navText_2')}}</li>
        <li class="nav-title mouse-poi flex-vc" @click="navClick(6)">我们的产品</li>
        <!-- <li class="nav-title mouse-poi flex-vc" @click="navClick(3)">{{$t('nav.navText_3')}}</li> -->
        <li class="nav-title mouse-poi flex-vc" @click="navClick(4)">{{$t('nav.navText_4')}}</li>
        <li class="nav-title mouse-poi flex-vc" @click="navClick(5)">{{$t('nav.navText_5')}}</li>
      </ul>
    </div>
    <div class="row_2 flex">
      <div class="warp-left flex-hc">
        <p class="company-msg"><span class="text">{{$t('footer.footText_1')}}</span>：023-67177907</p>
        <p class="company-msg"><span class="text">实体联盟联系电话</span>：15310443778</p>
        <p class="company-msg"><span class="text">乡村振兴联系电话</span>：13618235741</p>
        <p class="company-msg"><span class="text">{{$t('footer.footText_2')}}</span>：zhenhewang2015@126.com</p>
        <p class="company-msg"><span class="text">地址</span>：重庆市渝北区创意公园3A</p>
<!--        <p class="company-msg"><span class="text">{{$t('footer.footText_3')}}</span>：{{$t('footer.footText_6')}}</p>-->
<!--        <p class="company-msg"><span class="text">{{$t('footer.footText_3')}}</span>：{{lang == 'cn'?companyInfo.cn.address:companyInfo.en.address}}</p>-->
      </div>
      <div class="warp-right flex-vc">
        <div class="inner">
          <p class="phone-msg">华际科技-咨询服务热线</p>
          <p class="phone">023-67177907</p>
        </div>
        <div class="inner-qr_code">
          <img :src="companyInfo.wx_image" class="qr_code">
          <p class="qr_code-msg">{{$t('footer.footText_5')}}</p>
        </div>
      </div>
    </div>
    <div class="row_3 flex-vh">
      <p>版权公告©2022 重庆振合网网络科技有限公司。版权所有。</p>
      <div class="flex-vc">
        <img src="@/assets/forestage/images/bah.png" class="police-icon">
        <p class="mouse-poi" @click="openIcp(1)">渝公网安备 50011202503905号</p>
        <p class="fg">|</p>
        <img src="@/assets/forestage/images/bah.png" class="police-icon">
        <p class="mouse-poi" @click="openIcp(2)">渝ICP备{{companyInfo.icp}}</p>
      </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: 'Footer',
  data() {
    return {
      lang:'cn',
    }
  },
  computed: {
    ...mapGetters({
      companyInfo: 'companyInfo'
    }),
  },

  async created() {
    let that = this;
    window.addEventListener("setItemEvent", function(e) {
      if (e.key === "lang") {
        that.lang = e.newValue;
      }
    })

    await this.$store.dispatch('user/getCompanyInfo')
  },
  methods: {
    openIcp(type){
      let url = ''
      if(type === 1){
        url = 'https://www.beian.gov.cn/portal/registerSystemInfo'
      }else{
        url = 'https://beian.miit.gov.cn/#/Integrated/recordQuery'
      }
      window.open(url)
    },
    navClick(num) {
      this.activeIndex = num
      switch (num) {
        case 1:
          this.$router.push({ path: '/Home' })
          break;
        case 2:
          this.$router.push({ path: '/AboutUs' })
          break;
        // case 3:
        //   this.$router.push({ path: '/Cooperation' })
        //   break;
        case 4:
          this.$router.push({ path: '/News' })
          break;
        case 5:
          this.$router.push({ path: '/Contact' })
          break;
        case 6:
          this.$router.push({ path: '/MyProd' })
          break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}

.footer-container {
  height: 497px;
  padding: 0 360px;
  background: url('~@/assets/forestage/images/footer.png') no-repeat center / 100% 100%;
}

.row_1 {
  padding: 32px 0;

  .logo {
    width: 144px;
    height: 78px;
  }

  .nav-title {
    margin-right: 38px;
    font-size: 16px;
    color: #ffffff;

    &:hover {
      color: #e2e2e2;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.row_2 {
  height: 250px;
  padding: 30px 0;
  font-size: 16px;
  color: #ffffff;
  border-top: 1px solid rgba(225, 225, 225, 0.15);
  border-bottom: 1px solid rgba(225, 225, 225, 0.15);
  box-sizing: border-box;

  .warp-left {
    flex-direction: column;

    .text {
      display: inline-block;
    }
  }

  .company-msg {
    margin-bottom: 30px;
  }
  .company-msg:last-child {
    margin-bottom: 0;
  }
  .warp-right {
    display: flex;
  }

  .inner {
    margin-right: 30px;

    .phone-msg {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.7);
      text-align: right;
    }

    .phone {
      margin-top: 6px;
      font-size: 30px;
      font-weight: bold;
    }
  }

  .inner-qr_code {
    .qr_code {
      width: 150px;
      height: 150px;
    }

    .qr_code-msg {
      margin-top: 19px;
      font-size: 16px;
      text-align: center;
      font-weight: 500;
    }
  }
}

.row_3 {
  flex-direction: column;
  padding: 30px 0;
  font-size: 14px;
  color: #D4D7DB;

  .flex-vc {
    margin-top: 12px;
  }

  .police-icon {
    width: 20px;
    height: 20px;
  }
}
</style>
