import router from '../router/index.js'
import store from '@/store'
import {
  isAdmin
} from '@/utils/http.js'
// const routeList = router.options.routes[0].children

const routeList = ['/Home', '/AboutUs', '/Cooperation', '/News', '/Contact','/MyProd']
router.beforeEach((to, from, next) => {
  if (isAdmin()) {
    let userToken = window.localStorage.getItem('userToken')
    if (userToken) {
      next()
    } else {
      if (to.path == '/login') {
        next()
      } else {
        next('/login')
      }
    }
  } else {
    let active = routeList.findIndex((item) => item === to.path)
    if (active > -1) {
      active++
    }
    store.commit('user/setNavActive', active)
    next()
  }
})
