var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-index"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],class:{
    width1023: _vm.inputWitdh == '1023',
      width492: _vm.inputWitdh == '492',
      width800: _vm.inputWitdh == '800',
      width600: _vm.inputWitdh == '600',
      width396: _vm.inputWitdh == '396',
      width320: _vm.inputWitdh == '320',
      width300: _vm.inputWitdh == '300',
      width226: _vm.inputWitdh == '226',
      width274: _vm.inputWitdh == '274',
      width142: _vm.inputWitdh == '142',
      width280: _vm.inputWitdh == '280',
      width360: _vm.inputWitdh == '360',
      width100: _vm.inputWitdh == '100',
      width110: _vm.inputWitdh == '110',
      width50: _vm.inputWitdh == '50',
      width80: _vm.inputWitdh == '80',
      paddingRight50:_vm.watchLength,
    },attrs:{"maxlength":_vm.max,"disabled":_vm.disabled,"placeholder":_vm.placeholder},domProps:{"value":(_vm.text)},on:{"blur":_vm.inputblur,"input":function($event){if($event.target.composing)return;_vm.text=$event.target.value}}}),(_vm.watchLength)?_c('div',{staticClass:"home-index-length"},[_vm._v(_vm._s(_vm.textlength)+"/"+_vm._s(_vm.max))]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMsg),expression:"showMsg"}],staticClass:"errorMsg"},[_vm._v(_vm._s(_vm.msg))])])
}
var staticRenderFns = []

export { render, staticRenderFns }