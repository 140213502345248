import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale';
import enLocale from './locales/en.json'
import cnLocal from './locales/zh-CN.json'
import elEnLocale from 'element-ui/lib/locale/lang/en.js'
import elCnLocale from 'element-ui/lib/locale/lang/zh-CN.js'
Vue.use(VueI18n)
const messages = {
  en: {
    ...enLocale,
    ...elEnLocale,
  },
  cn: {
    ...cnLocal,
    ...elCnLocale
  }
}
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'cn',
  messages
})
locale.i18n((key, value) => i18n.t(key, value))
export default i18n