<template>
  <!-- 数字输入框 -->
  <div class="home-index">
    <input type="number" @blur="inputBlur" v-model="num" :placeholder="placeholder" :disabled="disabled" :class="{
      width443:inputWitdh == '443',
      width150:inputWitdh == '150',
      width100:inputWitdh == '100',
      width320:inputWitdh == '320',
      width227:inputWitdh == '227',
      width260:inputWitdh == '260',
      width274:inputWitdh == '274',
      width226:inputWitdh == '226',
      width120:inputWitdh == '120',
      width68:inputWitdh == '68',
      alignCenter:textAlingn == 'center'
    }" />
    <div v-show="showMsg" class="errorMsg">{{msg}}</div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        num: '',
        msg: '',
        showMsg: false,
        title: '',
      }
    },
    props: ['value', 'max', 'min', 'name', 'inputWitdh',
      'isCheck', 'aloneText', 'isInteger', 'disabled', 'isNull',
      'textAlingn', 'placeholder', 'promptText',
    ],
    created() {
      if (this.value) {
        this.num = Number(this.value)
      }
      this.setName(this.name)
    },
    watch: {
      value(val) {
        this.num = Number(val)
      },
      num(val) {
        if (this.isCheck) {
          this.verification()
        }
        this.$emit('input', val)
      },
      // msg(val) {
      //   if (val !== '') {
      //     let _that = this
      //     setTimeout(() => {
      //       _that.msg = ''
      //     }, 4000)
      //   }
      // },
    },
    methods: {
      // 处理name结尾的冒号
      setName(name) {
        if (this.name) {
          if (name.indexOf('：') != -1) {
            this.title = name.substr(0, name.length - 1)
          } else {
            this.title = name
          }
        } else {
          if (this.promptText) {
            this.title = this.promptText
          }
        }
      },
      inputBlur() {
        if (this.isCheck) {
          this.verification()
        }
      },
      verification() {
        if (this.isCheck) {
          this.showMsg = true
          if (!this.isNull) {
            if (this.num == '') {
              if (this.aloneText) {
                this.msg = '请输入' + this.aloneText
              } else {
                this.msg = '请输入' + this.title
              }
              return false;
            }
          }
          if (this.isInteger) {
            if (`${this.num}`.indexOf('.') != -1) {
              this.msg = '请输入整数'
              return false;
            }
          } else {
            var reg = /^(\-)*(\d+)\.(\d\d\d).*$/
            if (reg.test(this.num)) {
              this.msg = '请输入两位小数'
              return false;
            }
          }

          if (this.num > this.max) {
            this.msg = '最大值不能超过' + this.max
            return false;
          }
          if (this.num < this.min) {
            this.msg = '最小值不能低于' + this.min
            return false;
          }
          this.showMsg = false
          return true
        } else {
          return true
        }
      },
      alertMsg() {
        if (this.verification) {
          this.$notify.error({
            title: '提示',
            message: this.msg
          });
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .home-index {
    position: relative;

    input:focus {
      outline: none;
      border: 1px solid #409eff;

    }

    input::-webkit-outer-spin-button,

    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    .alignCenter {
      text-align: center;
      padding-left: 0;
    }

    // .alignleft {
    //   text-align: left;
    // }
    input {
      height: 40px;
      border: 1px solid #EAECF6;
      opacity: 1;
      border-radius: 5px;
      padding-left: 10px;
      // padding-left: 13px;
      // text-align: center;
    }

    .errorMsg {
      min-width: 250px;
      color: #E30C0C;
      font-size: 14px;
      position: absolute;
      top: 45px;
    }

    .width260 {
      width: 260px;
    }

    .width443 {
      width: 443px;
    }

    .width320 {
      width: 320px;
    }

    .width150 {
      width: 150px;
    }

    .width100 {
      width: 100px;
    }

    .width227 {
      width: 227px;
    }

    .width274 {
      width: 274px;
    }

    .width226 {
      width: 226px;
    }

    .width120 {
      width: 120px;
    }

    .width68 {
      width: 68px;
    }
  }
</style>
