<template>
  <!-- 是否必传 1:是 2:否 -->
  <!-- name:标题（2） -->
  <!-- isCheck（2）:是否需要验证 true:是  false:否  (默认不开启)-->
  <!-- css参数  isRedDot:{true:显示，false:隐藏   (默认不显示)}  -->
  <!-- css参数  isFlex:{true:标题输入框横向排列，false:标题输入框竖向排列}  （默认竖向）-->
  <!-- *为了适配视频分辨率兼容性rem 使用动态class -->
  <!-- max:最大值（2） min:最小值（2） -->
  <!-- aloneText：特殊的错误提示 （2）-->

  <div class="input-box">
    <div :class="isFlex ? 'inputFlex' : ''">
      <div class="input-title">
        <span :class="isRedDot ? '' : 'isRedDot'">*</span>
        {{ name }}
      </div>

      <div class="input-home">
        <inputNumberModule ref="inputNumber" :name="name" :isNull='isNull' :aloneText="aloneText"
          :promptText='promptText' :inputWitdh="inputWitdh" :max="max" :min="min" :isInteger='isInteger'
          :isCheck="isCheck" v-model="text" :disabled="disabled" :textAlingn="textAlingn"></inputNumberModule>
      </div>
    </div>
  </div>
</template>

<script>
  import inputNumberModule from "./module/input_number.vue";

  export default {
    components: {
      inputNumberModule
    },
    props: [
      "name",
      "type",
      "value",
      "max",
      "inputWitdh",
      "isFlex",
      "min",
      "isCheck",
      "isRedDot",
      "aloneText",
      'isInteger',
      'disabled',
      'isNull',
      'textAlingn', 
      'promptText',
    ],
    data() {
      return {
        text: "",
      };
    },
    created() {
      this.text = this.value;
    },
    watch: {
      value(val) {
        this.text = val;
      },
      text(val) {
        this.$emit("input", val);
      },
    },
    methods: {
      verification() {
        return this.$refs.inputNumber.verification();
      },
      alertMsg() {
        return this.$refs.inputNumber.alertMsg();
      },
    },
  };
</script>

<style scoped lang="scss">
  .input-box {
    .input-title {
      font-size: 18px;
      margin-bottom: 15px;
      color: #778ca2;

      span {
        color: #e30c0c;
      }
    }

    .inputFlex {
      display: flex;
      line-height: 40px;
    }

    .isRedDot {
      display: none;
    }
  }
</style>
