<template>
  <div class="layout-container" :class="isMobile?'mobile-h':'h'">
    <mobile-navigation @setType="setType" v-if="isMobile" class="mobile-head"></mobile-navigation>
    <navigation v-else></navigation>

    <div>
      <div v-if="isMobile" class="mobile-only"></div>
      <router-view></router-view>
    </div>
    <message v-if="!isMobile"></message>
    <page-foot v-if="!isMobile"></page-foot>
    <mobilePageFoot v-else-if="isMobile && routesType"></mobilePageFoot>
    <div v-if="isMobile && routesType" class="message-box flex-vh" @click="goToMessage">
      <img src="@/assets/forestage/images/msg-img.png" class="msg-img">
      <div class="message">
        在线留言
      </div>
    </div>
  </div>
</template>

<script>
import message from '@/components/Forestage/message.vue'
import { hasMobile } from '@/utils/mobile.js'
import { debounce } from 'lodash'
export default {
  components: { message },
  data () {
    return {
      isMobile: false,
      isSafari: false,
      routesType: true
    }
  },
  created () {
    this.isMobile = hasMobile()
  },
  mounted () {
    // window.addEventListener('resize', debounce(() => {
    // this.$store.commit('setMobile', hasMobile());
    // }));
    if (this.$router.currentRoute.path === '/message') {
      this.routesType = false
    } else {
      this.routesType = true
    }
  },
  methods: {
    setType (type) {
      this.created(type)
    },
    created (type) {
      this.routesType = type
    },
    goToMessage () {
      this.created(false)
      this.$router.push({ path: '/message' })
    }
  }
}
</script>

<style lang="scss" scoped>
.msg-img{
  width: 122.88px;
  height: 122.88px;
  margin-right: 20.48px;
}
.message-box {
  width: 100%;
  height: 250.88px;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #FFFFFF;
}
.message{
  font-weight: 400;
  font-size: 81.92px;
  color: #1B74E5;
}
.layout-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.h{
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
}
.mobile-h{
  height: 100%;
}

.mobile-only{
  height: 225.28px;
  color: #fff;
}
</style>
