import request from '@/utils/request'


//获取公司信息
export const getCompanyInfo = (data) => request('api/index/website', 'post', data)
//轮播图
export const getSwiperList = (data) => request('api/index/banner', 'post', data)
//提交留言
export const submitMessage = (data) => request('api/message/submit', 'post', data)
//获取新闻列表
export const getNewsList = (data) => request('api/news/list', 'post', data)
//获取新闻详情
export const getNewsDetail = (data) => request('api/news/info', 'post', data)