<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() { },
  methods: {},
  beforeDestroy() {
    this.$eventBus.$off('langChange')
  },
};
</script>
<style lang="scss">

</style>
