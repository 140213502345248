var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-index"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.num),expression:"num"}],class:{
    width443:_vm.inputWitdh == '443',
    width150:_vm.inputWitdh == '150',
    width100:_vm.inputWitdh == '100',
    width320:_vm.inputWitdh == '320',
    width227:_vm.inputWitdh == '227',
    width260:_vm.inputWitdh == '260',
    width274:_vm.inputWitdh == '274',
    width226:_vm.inputWitdh == '226',
    width120:_vm.inputWitdh == '120',
    width68:_vm.inputWitdh == '68',
    alignCenter:_vm.textAlingn == 'center'
  },attrs:{"type":"number","placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":(_vm.num)},on:{"blur":_vm.inputBlur,"input":function($event){if($event.target.composing)return;_vm.num=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMsg),expression:"showMsg"}],staticClass:"errorMsg"},[_vm._v(_vm._s(_vm.msg))])])
}
var staticRenderFns = []

export { render, staticRenderFns }