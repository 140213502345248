<template>
  <transition name="el-fade-in-linear">
    <div class="nav-container flex-vc" :class="{'position-change':whether,'bg-c-f':!isNavShow}">
      <div class="logo-warp mouse-poi" @click="goHome">
        <img src="@/assets/forestage/images/zhwLogo_1.png" class="logo-zhw-1" v-if="whether">
        <img src="@/assets/forestage/images/zhwLogo_1.png" class="logo-zhw-1" v-else>
      </div>

      <ul class="nav-block flex-vc">
        <li class="nav-title mouse-poi flex-vc" :class="{active:navActive === 1}" @click="navClick(1)">
          {{$t('nav.navText_1')}}</li>
        <li class="nav-title mouse-poi flex-vc" :class="{active:navActive === 2}" @click="navClick(2)">
          {{$t('nav.navText_2')}}</li>
          <li class="nav-title mouse-poi flex-vc" :class="{active:navActive === 6}" @click="navClick(6)">
            项目介绍</li>
        <!-- <li class="nav-title mouse-poi flex-vc" :class="{active:navActive === 3}" @click="navClick(3)">
          {{$t('nav.navText_3')}}</li> -->
        <li class="nav-title mouse-poi flex-vc" :class="{active:navActive === 4}" @click="navClick(4)">
          {{$t('nav.navText_4')}}</li>
        <li class="nav-title mouse-poi flex-vc" :class="{active:navActive === 5}" @click="navClick(5)">
          {{$t('nav.navText_5')}}</li>
      </ul>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Navigation',
  props: ['background', 'border', 'navStyle'],
  data () {
    return {
      whether: false,
      langShow: false,
      langIndex: 0,
      langText: '简体中文',
      langList: [{ name: '简体中文', type: 'cn' }, { name: 'English', type: 'en' }],
      oldScrollTop: 0,
      isNavShow: true,
      isReload: true,
      routes: ['/NewDetail']
    }
  },
  computed: {
    ...mapGetters({
      navActive: 'navActive'
    })
  },
  watch: {
    navActive (newVal, oldVal) {
      if (newVal === -1) {
        this.whether = true
      } else {
        this.whether = false
      }
    }
  },
  created () {
    const lang = localStorage.getItem('lang') || 'cn'
    if (lang === 'cn') {
      this.langText = '简体中文'
    } else {
      this.langText = 'English'
    }
    const active = localStorage.getItem('nav_active') * 1
    if (active === -1) {
      this.whether = true
    } else {
      this.whether = false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrolling)
  },
  methods: {
    goHome () {
      this.$router.push({ path: '/Home' })
    },
    navClick (num) {
      this.$store.commit('user/setNavActive', num)
      switch (num) {
        case 1:
          this.$router.push({ path: '/Home' })
          break
        case 2:
          this.$router.push({ path: '/AboutUs' })
          break
        // case 3:
        //   this.$router.push({ path: '/Cooperation' })
        //   break;
        case 4:
          this.$router.push({ path: '/News' })
          break
        case 5:
          this.$router.push({ path: '/Contact' })
          break
        case 6:
          this.$router.push({ path: '/MyProd' })
          break
      }
    },
    open () {
      this.langShow = !this.langShow
    },
    clickOutside () {
      this.langShow = false
    },
    langChange (item, index) {
      this.langText = item.name
      this.langIndex = index
      this.langShow = false
      this.$i18n.locale = item.type
      localStorage.setItem('lang', item.type)
      // this.$store.commit('user/lang', item.type)
      let companyInfo = this.$store.getters.companyInfo
      companyInfo = this.$langFilter(companyInfo)
      this.$store.commit('user/setCompanyInfo', companyInfo)
      const routePath = this.$route.path
      this.$eventBus.$emit('langChange', item.type, routePath)
    },
    scrolling () {
      const index = this.routes.indexOf(this.$route.path)
      if (this.isReload) {
        this.isNavShow = true
        this.isReload = false
      } else {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        const scrollStep = scrollTop - this.oldScrollTop
        this.oldScrollTop = scrollTop
        if (scrollStep <= 0 || index > -1) {
          this.isNavShow = true
        } else {
          this.isNavShow = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-logo{
  font-weight: bold;
  font-size: 36px;
  color: #333;
  margin-bottom: 3px;
}
.logo-name{
  font-size: 18px;
  color: #333;
}

.bgc-f80{
  background: rgba(255, 255, 255, 0.8);
}

.nav-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  padding-left: 375px;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1003;

  .logo-warp {
    .logo {
      width: 146px;
      height: 78px;
    }
    .logo-zhw{
      width: 140px;
      height: 72px;
    }
    .logo-zhw-1{
      width: 186px;
      height: 140px;
    }
  }

  .nav-block {
    height: 100%;
    margin-left: 285px;

    .nav-title {
      height: 100%;
      font-size: 16px;
      color: #333333;
      margin-right: 58px;

      &:hover {
        color: #3169FA;
      }

      &:last-child {
        margin: 0;
      }
    }

    .active {
      position: relative;
      font-weight: bold;
      color: #3169FA;

      &::after {
        content: ' ';
        position: absolute;
        bottom: 1px;
        left: 50%;
        transform: translateX(-50%);
        width: 64px;
        height: 0px;
        border-bottom: 2px solid #3169FA;
      }
    }
  }

  .lang-container {
    position: relative;
    margin-left: 111px;
    font-size: 16px;
    color: #ffffff;
  }

  .lang-block {
    justify-content: space-between;
    width: 119px;
    height: 40px;
    padding: 0 9px 0 16px;
    border: 1px solid #FFFFFF;
    border-radius: 6px;

    &:hover {
      border: 1px solid #e9e9e9;
    }

    .rotate-warp {
      transition: all 0.2s;
    }

    .rotate {
      transition: all 0.2s;
      transform: rotate(180deg);
    }
  }

  .option-block {
    position: absolute;
    left: 0;
    top: 72px;
    width: 119px;
    padding: 10px 0;
    background: rgba(0, 0, 0, 0.39);
    border: 1px solid #e9e9e9;
    border-radius: 6px;

    .option {
      height: 40px;
      padding: 0 20px;

      &:hover {
        background-color: #1B74E5;
      }
    }
  }
}

.position-change {
  position: static;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);

  .nav-block .nav-title {
    color: #333;
  }

  .lang-container {
    color: #333;

    .lang-block {
      border-color: #999999;

      &:hover {
        border-color: #888888;
      }
    }

    .option-block {
      background: rgba(255, 255, 255, 0.5);

      .option {

        &:hover {
          background-color: #e0e0e0;
        }
      }
    }
  }
}
</style>
