<template>
  <div>
    <img @click="dialogVisible = true" src="@/assets/forestage/images/yyzx.png" class="yyzx">
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="48%"
      :show-close="false"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <div class="pop-box">
        <div class="pop-head flex-vc">
          <img src="@/assets/forestage/images/tc-img.png" class="tc-img animate__animated animate__zoomIn">
          <div class="pop-title animate__animated animate__zoomIn">在线留言</div>
        </div>
        <div class="pop-content flex-vh flex-direction">
          <div class="input-box m-b-20 animate__animated animate__fadeInLeft">
            <div class="input-title flex-vc">
              <span class="input-x">*</span>
              <div class="input-name">姓名</div>
            </div>
            <el-input class="input" v-model="formData.name" placeholder="请输入"></el-input>
          </div>
          <div class="input-box m-b-20 animate__animated animate__fadeInRightBig">
            <div class="input-title flex-vc">
              <span class="input-x">*</span>
              <div class="input-name">手机号</div>
            </div>
            <el-input type="number" v-model="formData.phone" placeholder="请输入"></el-input>
          </div>
          <div class="input-box m-b-40 animate__animated animate__fadeInLeft">
            <div class="input-title flex-vc">
              <span class="input-x">*</span>
              <div class="input-name">留言内容</div>
            </div>
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入"
              v-model="formData.content">
            </el-input>
          </div>
          <div class="pop-but animate__animated animate__bounceIn" @click="clickSubmit">
            提交
          </div>
        </div>
        <img src="@/assets/forestage/images/tc-gb.png" @click="gbPop" class="tc-gb">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mixinsHomeMsg from '@/mixins/forestage/homeMsg.js'
import { submitMessage } from '@/api/Forestage/index'
export default {
  components: {},
  mixins: [mixinsHomeMsg],
  data () {
    return {
      dialogVisible: false,
      formData: {
        name: '',
        phone: '',
        content: ''
      },
      isRequest: false
    }
  },
  mounted () {},
  methods: {
    clickSubmit () {
      if (this.isRequest) return
      if (!this.formData.name) {
        this.$message.error('请输入姓名')
        return
      }
      if (!this.formData.phone) {
        this.$message.error('请输入手机号')
        return
      }
      if (!this.formData.content) {
        this.$message.error('请输入留言内容')
        return
      }
      this.isRequest = true
      submitMessage(this.formData).then((res) => {
        if (res.code === 1) {
          this.$message.success('提交成功')
          this.gbPop()
        } else {
          this.$message.error(res.msg)
        }
      })
      this.isRequest = false
    },
    gbPop () {
      this.dialogVisible = false
      this.formData = {
        name: '',
        phone: '',
        content: ''
      }
    },
    handleClose (done) {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.tc-gb{
  width: 44px;
  height: 44px;
  position: absolute;
  top: -33px;
  right: -21px;
  cursor: pointer;
}
.pop-but{
  width: 520px;
  height: 44px;
  line-height: 44px;
  background: #1B74E5;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
}
.input{
  width: 520px;
  height: 44px;
  border-radius: 6px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.input-box{
  width: 520px;
}
.input-title {
  margin-bottom: 12px;
}
.input-x{
  font-size: 16px;
  color: #FB3737;
  margin-right: 2px;
}
.input-name {
  font-size: 16px;
  color: #000;
}
.pop-title{
  font-weight: 500;
  font-size: 24px;
  color: #FFFFFF;
}
.pop-content{
  width: 100%;
  height: 560px;
  background: #fff;
  border-radius: 0 0 12px 12px;
}
.yyzx{
  width: 100px;
  height: 85px;
  position: fixed;
  right: 0;
  top: 65vh;
  z-index: 100;
  cursor: pointer;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body{
  padding: 0;
  border-radius: 0 0 12px 12px;
}
.pop-box{
  padding: 0;
  background: transparent;
  box-shadow: -2px 6px 20px 0px rgba(153,153,153,0.25);
  position: relative;
}
.pop-head {
  background: linear-gradient( 90deg, #1D69FF 0%, #4282FF 100%);
  border-radius: 12px 12px 0px 0px;
  padding: 7px 0 7px 20px;
  box-sizing: border-box;
  position: relative;
  top: -10px;
}
.tc-img{
  width: 66px;
  height: 66px;
  margin-right: 7px;
}
</style>
