import Navigation from './Forestage/Navigation.vue'
import PageFoot from './Forestage/PageFoot.vue'
import Banner from './Forestage/Banner.vue'
import MobileNavigation from './Forestage/MobileNavigation.vue'
import MobilePageFoot from './Forestage/MobilePageFoot.vue'
// admin
import InputText from './Admin/from_text.vue'
import InputNum from './Admin/from_number.vue'

export default {
  install(Vue) {
    Vue.component('Navigation', Navigation)
    Vue.component('PageFoot', PageFoot)
    Vue.component('Banner', Banner)
    Vue.component('MobileNavigation', MobileNavigation)
    Vue.component('MobilePageFoot', MobilePageFoot)
    // admin
    Vue.component('InputText', InputText)
    Vue.component('InputNum', InputNum)
  }
}
