import { submitMessage } from '@/api/Forestage/index'
export default {
  data() {
    return {
      fromData: {
        name: '',
        phone: '',
        content: '',
      },
    }
  },
  methods: {
    async submitMessage() {
      const res = await submitMessage(this.fromData)
      if (res.code === 1) {
        this.$notify({
          title: '成功',
          message: '留言成功',
          type: 'success',
          duration: 2000
        });
      }
      return res
    }
  },
}