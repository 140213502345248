export const sumStrLength = (str) => {
  let evenNum = 0
  let oddNum = 0
  let sum = 0;
  let ary = str.split("");
  ary.forEach(item => {
    if (item.charCodeAt(0) < 299) {
      oddNum++
    } else {
      evenNum++
    }
  });
  let oddRes = oddNum / 2
  Math.floor(oddRes)
  sum = evenNum + oddRes
  return sum;
}

