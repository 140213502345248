export default [
  {
    path: '/',
    name: 'AdminHome',
    redirect: '/infoManage',
    component: () => import('../views/Admin/index.vue'),
    children:[
      {
        path: '/infoManage',
        name: 'InfoManage',
        meta:{
          title:'基本信息',
          icon:'position'
        },
        component: () => import('../views/Admin/infoManage.vue'),
      },
      {
        path: '/smsManage',
        name: 'SmsManage',
        meta:{
          title:'通知管理',
          icon:'phone-outline'
        },
        component: () => import('../views/Admin/smsManage.vue'),
      },
      {
        path: '/userManage',
        name: 'UserManage',
        meta:{
          title:'用户管理',
          icon:'user'
        },
        component: () => import('../views/Admin/userManage.vue'),
      },
      {
        path: '/carouselManage',
        name: 'CarouselManage',
        meta:{
          title:'轮播管理',
          icon:'camera'
        },
        component: () => import('../views/Admin/carouselManage.vue'),
      },
      {
        path: '/newsManage',
        name: 'NewsManage',
        meta:{
          title:'文章管理(官网)',
          icon:'chat-line-square'
        },
        component: () => import('../views/Admin/newsManage.vue'),
      },
      {
        path: '/leaveManage',
        name: 'LeaveManage',
        meta:{
          title:'留言管理',
          icon:'chat-dot-square'
        },
        component: () => import('../views/Admin/leaveManage.vue'),
      },
      {
        path: '/grantList',
        name: 'GrantList',
        meta:{
          title:'公司活动(公众号)',
          icon:'chat-dot-square'
        },
        component: () => import('../views/Admin/grant-list.vue'),
      },
      {
        path: '/ruralNotice',
        name: 'RuralNotice',
        meta:{
          title:'新闻宣传(公众号)',
          icon:'chat-dot-square'
        },
        component: () => import('../views/Admin/rural-notice.vue'),
      },
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/Admin/login.vue'),
  },
]
