import axios from 'axios'
import store from '@/store'
import Cookies from 'cookies'
import { MessageBox, Message, Loading } from 'element-ui'
import { isAdmin, langFilter } from '@/utils/http.js'
const service = axios.create({
  timeout: 5000
})
const lang = localStorage.getItem('lang') || 'cn'
service.interceptors.request.use(
  config => {
    if (config.isLoading) {
      startLoading()
    }
    if (localStorage.getItem('userToken')) {
      config.headers.ApiToken = localStorage.getItem('userToken')
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    if (response.config.isLoading) {
      endLoading();
    }
    const res = response.data
    if (res.code === 0) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
    } else if (res.code === 401) {
      localStorage.clear()
      Message({
        message: '登录信息过期，请重新登录',
        type: 'error',
        duration: 3 * 1000,
        onClose: () => {
          window.location.href = '/'
        }
      })
    } else if (res.code === 1) {
      if (isAdmin()) {
        return res
      } else {
        // 前台响应数据
        let { data } = res
        res.data = langFilter(data)
        return res
      }
    } else if (res.code === 402) {
      Message({
        message: '暂无权限访问或操作该页面',
        type: 'error',
        duration: 3 * 1000,
      })
    }
    return res
  },
  error => {
    Message({
      message: '系统错误',
      type: 'error',
      duration: 3 * 1000
    })
    endLoading();
    return Promise.reject(error)
  }
)

let loading
function startLoading() {
  loading = Loading.service({
    lock: true,
    text: '加载中',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}
function endLoading() {
  loading.close()
}

const request = (url, method = 'get', data, isLoading = false) => {
  const config = {}
  if (data) {
    if (method === 'get') {
      config.params = data
    } else {
      config.data = data
    }
  }


  return service({
    url,
    method,
    isLoading,
    ...config
  })
}


export default request
