import { getCompanyInfo } from '@/api/Forestage/index.js'
import Cookies from 'js-cookie'
const getDefaultState = () => {
  return {
    companyInfo: JSON.parse(Cookies.get('company') || "{}"),
    navActive: localStorage.getItem('nav_active') * 1 || 1,
    // lang:'',
  }
}
const state = getDefaultState()
const mutations = {
  setCompanyInfo(state, info) {
    state.companyInfo = info
    Cookies.set('company', JSON.stringify(info))
  },
  setNavActive(state, active) {
    console.log(state,active);
    state.navActive = active
    localStorage.setItem('nav_active', active)
  },
}
const actions = {
  getCompanyInfo({ commit }) {
    getCompanyInfo().then(res => {
      commit('setCompanyInfo', res.data)
    })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
