<template>
  <!-- 文本输入框 -->
  <div class="home-index">
    <input v-model="text" :maxlength="max" :disabled="disabled" @blur="inputblur" :placeholder="placeholder" :class="{
      width1023: inputWitdh == '1023',
        width492: inputWitdh == '492',
        width800: inputWitdh == '800',
        width600: inputWitdh == '600',
        width396: inputWitdh == '396',
        width320: inputWitdh == '320',
        width300: inputWitdh == '300',
        width226: inputWitdh == '226',
        width274: inputWitdh == '274',
        width142: inputWitdh == '142',
        width280: inputWitdh == '280',
        width360: inputWitdh == '360',
        width100: inputWitdh == '100',
        width110: inputWitdh == '110',
        width50: inputWitdh == '50',
        width80: inputWitdh == '80',
        paddingRight50:watchLength,
      }" />
    <div class="home-index-length" v-if="watchLength">{{textlength}}/{{max}}</div>
    <div v-show="showMsg" class="errorMsg">{{ msg }}</div>
  </div>
</template>

<script>
  import {
    sumStrLength
  } from "@/assets/admin/js/sumStrLength.js"
  export default {
    computed: {
      textlength() {
        return this.text.length > 0 ? this.text.length : 0
      },
    },
    data() {
      return {
        title: "",
        text: "",
        msg: "",
        showMsg: false,
        placeholder: "",
      };
    },
    props: [
      "value",
      "max",
      "min",
      "name",
      "inputWitdh",
      "isCheck",
      "textPlaceholder",
      'disabled',
      'password','verifyEmail',
      'isSumStrLength', 'weekly', 'promptText', 'verifyPhone',
      'maxLengths', 'isCheckNum', 'isDecimal', 'watchLength'
    ],
    created() {
      this.text = this.value;
      this.setName(this.name);
      this.getPlaceholder(this.textPlaceholder);
    },
    watch: {
      value(val) {
        this.text = val
      },
      text(val) {
        if (this.isCheck) {
          this.verification();
        }
        this.$emit("input", val);
      },
      msg(val) {
        if (val !== '') {
          let _that = this
          setTimeout(() => {
            _that.msg = ''
          }, 4000)
        }
      },
    },
    methods: {
      // 处理文本默认值
      getPlaceholder(text) {
        if (text) {
          this.placeholder = text;
        } else {
          this.placeholder = "";
        }
      },
      // 处理name结尾的冒号
      setName(name) {
        if (name) {
          if (name.indexOf("：") != -1) {
            this.title = name.substr(0, name.length - 1);
          } else {
            this.title = name;
          }
        } else {
          if (this.promptText) {
            this.title = this.promptText
          }
        }
      },
      inputblur() {
        if (this.isCheck) {
          this.verification();
        }
      },
      verification() {
        if (this.isCheck) {
          this.showMsg = true;
          if (!this.text) {
            if (this.disabled) {
              this.msg = "请选择" + this.title;
              return false
            } else {
              this.msg = "请输入" + this.title;
              return false
            }
          }
          if(this.verifyEmail){
            let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
            if(!reg.test(this.text)){
              this.msg = "请输入正确的邮箱"
              return false
            }
          }
          if (this.password) {
            if (this.verifyPhone) {
              let a = /^1\d{10}$/
              let b = /^\d{3,4}-\d{7,8}$/
              if ((!a.test(this.text))&&(!b.test(this.text))) {
                this.msg = this.title + '请输入正确的电话';
                return false
              }
            } else {
              let a = /[^\w\.\/]/ig
              if (a.test(this.text)) {
                this.msg = '请输入正确的数字';
                return false
              }
            }

            if (this.isCheckNum) {
              if (this.text == 0) {
                this.msg = this.title + '最小为1';
                return false
              }
            }
            if (this.isDecimal) {
              if (!Number.isInteger(Number(this.text))) {
                this.msg = '请输入正确的数字';
                return false
              }
            }
            if (this.weekly) {
              let reg = /^[1-7]$/
              if (!reg.test(this.text)) {
                this.msg = '请输入正确的日期';
                return false
              }
            }
          }
          if (this.isSumStrLength) {
            if (sumStrLength(this.text) > this.maxLengths) {
              this.msg = this.title + '过长';
              return false
            }
          }
          if (this.min && this.text.length < this.min) {
            this.msg = this.title + '最少' + this.min + '位';
            return false
          }
          this.showMsg = false;
          return true
        } else {
          return true
        }
      },
      alertMsg() {
        if (this.verification) {
          this.$notify.error({
            title: "提示",
            message: this.msg,
          });
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .home-index {
    position: relative;

    .errorMsg {
      min-width: 200px;
      color: #e30c0c;
      font-size: 14px;
      position: absolute;
      top: 45px;
    }

    .home-index-length {
      position: absolute;
      top: 13px;
      left: 550px;
    }
  }

  .paddingRight50 {
    padding-right: 50px;
  }

  .width1023 {
    width: 1023px;
  }
  .width800{
    width: 800px;
  }
  .width360 {
    width: 360px;
  }

  .width600 {
    width: 600px;
  }

  .width492 {
    width: 492px;
  }

  .width80 {
    width: 80px;
  }

  .width280 {
    width: 280px;
  }

  .width396 {
    width: 396px;
  }

  .width110 {
    width: 110px;
  }

  .width320 {
    width: 320px;
  }

  .width300 {
    width: 300px;
  }

  .width226 {
    width: 226px;
  }

  .width274 {
    width: 274px;
  }

  .width142 {
    width: 142px;
  }

  .width100 {
    width: 100%;
  }

  .width50 {
    width: 50px;
  }

  input:focus {
    outline: none;
    border: 1px solid #409eff;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-input-placeholder {
    color: #a9b6c4;
    font-size: 15px;
  }

  input {
    height: 40px;
    border: 1px solid #eaecf6;
    opacity: 1;
    border-radius: 5px;
    padding-left: 13px;
    font-size: 15px;
    position: relative;
  }
</style>
