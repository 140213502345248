<template>
  <div class="head-box">
    <div class="head-view">
      <!-- <img class="img-logo" src="../../assets/forestage/img/m_logo.png" alt=""> -->
      <div class="img-logo-zhw">
        <img src="@/assets/forestage/images/zhwLogo_1.png">
      </div>
      <div class="head-view-right" @click="showNav = true">
        <img src="../../assets/forestage/img/m.png" alt="">
        <div>{{$t('nav.navText_6')}}</div>
      </div>
    </div>

    <div class="nav-view" @click="closeNav()" v-if="showNav" @touchmove.prevent>
      <div class="nav-list" ref="nav" @click.stop="" :class="showNav==false?'':'open-view'">
          <div class="shut" @click="closeNav()">×</div>
          <div>
            <ul class="">
              <li class="nav-li" :class="{active:navActive === 1}" @click="navClick(1)">
                {{$t('nav.navText_1')}}</li>
              <li class="nav-li" :class="{active:navActive === 2}" @click="navClick(2)">
                {{$t('nav.navText_2')}}</li>
              <!-- <li class="nav-li" :class="{active:navActive === 3}" @click="navClick(3)">
                {{$t('nav.navText_3')}}</li> -->
                <li class="nav-li" :class="{active:navActive === 6}" @click="navClick(6)">
                  项目介绍</li>
              <li class="nav-li" :class="{active:navActive === 4}" @click="navClick(4)">
                {{$t('nav.navText_4')}}</li>
              <li class="nav-li" :class="{active:navActive === 5}" @click="navClick(5)">
                {{$t('nav.navText_5')}}</li>
            </ul>
            <!-- <ul class="nav-ul">
              <li class="nav-li" :class="langIndex==i?'c-1':''" v-for="(item,i) in langList" :key="i" @click="langChange(item,i)">{{item.name}}</li>
            </ul> -->
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      navActive: 'navActive'
    })
  },
  name: 'MobileNavigation',
  data () {
    return {
      langIndex: 0,
      showNav: false,
      langList: [{ name: '中文', type: 'cn' }, { name: 'EN', type: 'en' }]
    }
  },
  created () {
    const lang = localStorage.getItem('lang') || 'cn'
    if (lang === 'cn') {
      this.langIndex = 0
    } else {
      this.langIndex = 1
    }
  },
  methods: {
    closeNav () {
      const _this = this
      _this.$refs.nav.classList.remove('open-view')
      _this.$refs.nav.classList.add('close-view')
      setTimeout(() => {
        _this.showNav = false
      }, 200)
    },
    navClick (num) {
      this.$emit('setType', true)
      this.$store.commit('user/setNavActive', num)
      const _this = this
      switch (num) {
        case 1:
          _this.closeNav()
          setTimeout(() => {
            _this.$router.push({ path: '/Home' })
          }, 200)
          break
        case 2:
          _this.closeNav()
          setTimeout(() => {
            _this.$router.push({ path: '/AboutUs' })
          }, 200)
          break
          // case 3:
          //   _this.closeNav()
          //   setTimeout(()=>{
          //     _this.$router.push({ path: '/Cooperation' })
          //   },200)
          //   break;
        case 4:
          _this.closeNav()
          setTimeout(() => {
            _this.$router.push({ path: '/News' })
          }, 200)
          break
        case 5:
          _this.closeNav()
          setTimeout(() => {
            _this.$router.push({ path: '/Contact' })
          }, 200)
          break
        case 6:
          _this.closeNav()
          setTimeout(() => {
            _this.$router.push({ path: '/MyProd' })
          }, 200)
          break
      }
    },
    langChange (item, index) {
      this.langText = item.name
      this.langIndex = index
      this.langShow = false
      this.$i18n.locale = item.type
      localStorage.setItem('lang', item.type)
      let companyInfo = this.$store.getters.companyInfo
      companyInfo = this.$langFilter(companyInfo)
      this.$store.commit('user/setCompanyInfo', companyInfo)
      const routePath = this.$route.path
      this.$eventBus.$emit('langChange', item.type, routePath)
    }
  }
}
</script>

<style lang="scss" scoped>
    .head-box{
      width: 100vw;
      height: 225.28px;
      position: fixed;
      z-index: 2999;
      background-color: #fff;

      .c-1{
        color: #1B74E5 !important;
      }

      .active{
        color: #1B74E5 !important;
        background-color: #F2F7FE;
      }

      .nav-ul{
        width: 100%;
        height: 225.28px;
        display: flex;
      }
      .nav-li{
        width: 100%;
        height: 285.28px;
        line-height: 285.28px;
        box-sizing: border-box;
        padding-left: 81.92px;
        color: #666666;
        font-size: 81.79px;
      }

      .close-view{
        animation: close .3s;
        @keyframes close {
            from{right: 0;}
            to{right: -783.36px;display: none;}
        }
      }

      .open-view{
        animation: mymove .3s;
        @keyframes mymove {
            from{right: -783.36px;}
            to{right: 0;}
        }
      }

      .nav-view{
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3000 !important;

        .nav-list{
          width: 783.36px;
          height: 100%;
          background-color: #fff;
          position: absolute;
          right: 0;
          top: 0;
          .shut{
            box-sizing: border-box;
            width: 100%;
            height: 225.28px;
            background-color: #1B74E5;
            line-height: 225.28px;
            text-align: right;
            color: #fff;
            font-size: 100px;
            padding-right:81.92px;
          }
        }
      }

      .head-view{
        display: flex;
        justify-content: space-between;
        padding: 0 81.92px;
        .img-logo{
          padding-top: 17.92px;
          width: 348.16px;
          height: 184.32px;
        }
        .img-logo-zhw{
          padding-top: 17.92px;
          width: 348.16px;
          height: 184.32px;
          position: relative;
          img{
            position: absolute;
            top: -76px;
            left: 0;
            width: 520px;
            height: 370px;
          }
        }
        .head-view-right{
          text-align: center;
          padding-top: 17.92px;
          img{
            width: 112.64px;
            height: 112.64px;
          }
          div{
            font-size: 51.2px;
            color: #333333;
          }
        }
      }
    }
</style>
