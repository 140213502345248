import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n.js'
import ElementUI from 'element-ui'
import '@/styles/index.scss'
import 'animate.css'
import '@/assets/icon/iconfont.css'
import '@/directive/ClickScope.js'
import '@/utils/flexible.js'
import '@/directive/directives.js'
import 'element-ui/lib/theme-chalk/index.css'
import './utils/routerEach'
import Components from './components'
import { langFilter } from '@/utils/http.js'
import { WOW } from 'wowjs'
import tool from "./utils/tools";
import '@/assets/css/common.css'

Vue.use(tool);
Vue.use(ElementUI)
Vue.use(Components)

Vue.prototype.getStaticFilePath = (urlName) => {
	let url = 'https://jhxcnc.oss-cn-chengdu.aliyuncs.com/image/'
	return url + urlName + '?x-oss-process=image/auto-orient,1/quality,q_80'
}

Vue.prototype.$wow = new WOW({
  boxClass: 'wow',
  animateClass: 'animate__animated',
  offset: 0,
  mobile: true,
  live: false,
})
Vue.prototype.$langFilter = langFilter
Vue.prototype.$eventBus = new Vue()
Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
